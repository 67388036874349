@import "./roots.scss";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/animate.css/animate.css";

body {
  transition: .5s background-color;
  overflow-y: scroll;
  font-family: 'Montserrat', sans-serif;
}

a {
  word-break: break-word;

  &:hover {
    text-decoration: none;
  }
}

#root {
  min-height: 100vh;
}

.bg-gradient {
  background-image: linear-gradient(241deg, $primary 0%, $secondary 100%)
}

.bg-gradient-reverse {
  background-image: linear-gradient(241deg, $secondary 0%, $primary 100%)
}

.text-gradient {
  background-image:linear-gradient($primary,$secondary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stream-frame {
  border: 0;
  box-shadow: rgba(0,0,0,0.4) 0 0 5px 5px;
  border-radius: 5px;
}

h1, h2, h3, h4, h5, h6 {
  //font-weight: bold;
}

.embed-responsive {
  overflow: visible;
}

.masthead-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  a {
    color: white;

    &:hover {
      color: $secondary;
      transition: 0.5s;
    }
  }
}

.description {
  font-style: italic;
  letter-spacing: 1.4px;
  font-size: 1.1rem;
  line-height: 2rem;
  text-align: center;
}

.social-icons {
  a {
    display: inline-block;
    height: 3.5rem;
    width: 3.5rem;
    color: white !important;
    border-radius: 100%;
    text-align: center;
    font-size: 1.5rem;
    line-height: 3.5rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: $secondary;
      background-image: none;
    }
  }
}
