$primary: #ff8177;
$secondary: #B13F46;
//$primary: #569CDE;
//$secondary: #B92DD3;

$text: #8faccd;

$nav-bg: rgba(241, 241, 241, 0.14);
$nav-text: rgb(220, 220, 220);

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
